import { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import CreateForm from './components/form/Form';
import Home from './components/home/Home';
import LoginForm from './components/login/LoginForm';
import Navbar from './components/header/Navbar';
import Users from './components/users/users';
import UserForm from './components/users/userForm';


const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/form" element={<CreateForm />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/form" element={<UserForm />} />
          <Route path="/edit/:code" element={<CreateForm />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
