import moment from "moment";

export const mapListSertifikasi = (listSertifikasi: any) => {
  return listSertifikasi.map((item: any) => {
    const formattedItem = { ...item };

    formattedItem.tanggalSelesaiUji = formatDate(item.tanggalSelesaiUji);
    formattedItem.tanggalAkhirMasaEdar = formatDate(item.tanggalAkhirMasaEdar);

    return formattedItem;
  });
};

const formatDate = (dateString: string | undefined): string => {
  return dateString ? moment(dateString).format("DD MMM YYYY") : "";
};
