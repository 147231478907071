import { FileExcelFilled, InboxOutlined } from "@ant-design/icons";
import { Button, UploadProps, message } from "antd";
import { Content } from "antd/es/layout/layout";
import Dragger from "antd/es/upload/Dragger";
import HttpService from "../../services/HttpService";

const BulkInsert = () => {
    const httpService = new HttpService();

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
        async customRequest(options) {
            const { onSuccess, file } = options;
            try {
                await httpService.upload(file);
                onSuccess!("Ok");
                message.success('File uploaded successfully.');
            } catch (e) {
                message.error('File upload failed.');
            }

        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <Content >
            <Button type="primary" style={{ marginBottom: 10 }} icon={<FileExcelFilled />} href="/template.xlsx" download>Download Template</Button>
            <Dragger {...props} >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
            <p style={{ fontSize: 12, color: 'red' }}>*The uploaded file must align with the provided template</p>
            <p style={{ marginTop: -10, fontSize: 12, color: 'red' }}>*The file will be processed once the upload is complete</p>
        </Content>
    );
};

export default BulkInsert;

