import { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Table, message, DatePicker, InputNumber, Modal } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { CheckOutlined, CloseCircleOutlined, FileExcelOutlined, PercentageOutlined } from '@ant-design/icons';
import '../../styles/CreateForm.css';
import HttpService from '../../services/HttpService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Sertifikasi } from '../../sertifikasi.type';
import { fieldsDefinition } from './form.constants';
import util from '../../common/util';
import Navbar from '../header/Navbar';
import BulkInsert from '../bulkInsert/BulkInsert';

const CreateForm = () => {
  const { code } = useParams();
  const httpService = new HttpService();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<Sertifikasi>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (code) {
          const data = await httpService.getSertifikasiByCode(code);
          setInitialValues(util.mapToSertifikasi(data.sertifikasi));
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching sertifikasi:', error);
        message.error('Failed to fetch sertifikasi');
      }
    };

    fetchData();
  }, []);

  const onFinish = async (values: any) => {
    try {
      const payload = {
        ...values,
        tanggalAkhirMasaEdar: values.tanggalAkhirMasaEdar ? new Date(values.tanggalAkhirMasaEdar).toLocaleString() : '',
        tanggalPanen: values.tanggalPanen ? new Date(values.tanggalPanen).toLocaleString() : '',
        tanggalSelesaiUji: values.tanggalSelesaiUji ? new Date(values.tanggalSelesaiUji).toLocaleString() : '',
        ta: values.ta ? new Date(values.ta).getFullYear() : ''
      }

      if (code) {
        await httpService.updateSertifikasi(initialValues!.id as string, payload);
        message.success('Sertifikasi updated successfully');
      } else {
        await httpService.createSertifikasi(payload);
        message.success('Sertifikasi created successfully');
      }
      navigate('/');
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to perform operation');
    }
  };

  const showBulkInsertModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (isLoading) {
    return (<div></div>);
  } else {
    return (
      <div>
        <Navbar />
        <Content className="site-layout" style={{ marginTop: 20 }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: '650px', display: 'flex', justifyContent: 'center' }}>
            <Card className='custom-card'>
              {!code ? <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button onClick={showBulkInsertModal} icon={<FileExcelOutlined />} type="primary" htmlType="submit" style={{ background: '#059749', borderColor: '#059749' }}>
                  Import from Excel
                </Button>
                <Modal footer={[]} title="Bulk Insert" open={isModalOpen} onCancel={handleCancel}>
                  <BulkInsert />
                </Modal>
              </div> : <div></div>}

              <Form
                layout="vertical"
                name="nest-messages"
                onFinish={onFinish}
                initialValues={initialValues}
                style={{ marginTop: '10px' }}
              >
                <Table
                  bordered
                  pagination={false}
                  size="middle"
                  dataSource={fieldsDefinition}
                  rowKey="name"
                  style={{ marginBottom: 20 }}
                  showHeader={false}
                >
                  <Table.Column title="Field" dataIndex="label" key="label" />
                  <Table.Column title="Input" dataIndex="name" key="name" render={(name, record: any) => (
                    record.type === 'number' ? (
                      <Form.Item name={name} help={`Value should be between 0 and 1`}>
                        <InputNumber
                          max={1}
                          min={0}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    ) :
                      <Form.Item name={name}>
                        {record.type === 'date' ? (
                          <DatePicker style={{ width: '100%' }} />
                        ) : record.type === 'year' ? (
                          <DatePicker style={{ width: '100%' }} picker='year' />
                        ) : (
                          <Input style={{ maxWidth: '100%' }} />
                        )}
                      </Form.Item>
                  )} />
                </Table>
                <Form.Item style={{ textAlign: 'center' }}>
                  <Button icon={<CheckOutlined />} type="primary" htmlType="submit" style={{ background: '#059749', borderColor: '#059749' }}>
                    Submit
                  </Button>
                  &nbsp;
                  <Link to='/'>
                    <Button icon={<CloseCircleOutlined />} type="primary" danger>
                      Cancel
                    </Button>
                  </Link>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Content>
      </div>

    );
  }
};

export default CreateForm;

