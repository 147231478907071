export const fieldsDefinition = [
  { name: "noBerkas", label: "No Berkas", type: "input" },
  { name: "noLot", label: "No LOT", type: "input" },
  { name: "noInduk", label: "No. Induk", type: "input" },
  { name: "produsen", label: "Produsen", type: "input" },
  { name: "alamat", label: "Alamat", type: "input" },
  { name: "jenisTanaman", label: "Jenis Tanaman", type: "input" },
  { name: "varietas", label: "Varietas", type: "input" },
  { name: "kelasBenih", label: "Kelas Benih", type: "input" },
  { name: "tanggalPanen", label: "Tanggal Panen", type: "date" },
  { name: "beratKemasan", label: "Berat Kemasan", type: "input" },
  { name: "jumlahStokBenih", label: "Jumlah Stok Benih", type: "input" },
  { name: "tanggalSelesaiUji", label: "Tanggal Selesai Uji", type: "date" },
  {
    name: "tanggalAkhirMasaEdar",
    label: "Tanggal Akhir Masa Edar",
    type: "date",
  },
  { name: "noSeriAwal", label: "No Seri Awal", type: "input" },
  { name: "noSeriAkhir", label: "No Seri Akhir", type: "input" },
  { name: "kadarAir", label: "Kadar Air", type: "number" },
  { name: "dayaBerkecambah", label: "Daya Berkecambah", type: "number" },
  { name: "benihMurni", label: "Benih Murni", type: "number" },
  { name: "cvlLapang", label: "CVL Lapang", type: "number" },
  { name: "kotoranBenih", label: "Kotoran Benih", type: "number" },
  {
    name: "gulma",
    label: "Benih Tanaman Lain/Gulma",
    type: "number",
  },
  { name: "benihWarnaLain", label: "Benih Warna Lain", type: "number" },
  { name: "ta", label: "TA", type: "year" },
];
