import { DeleteOutlined, DownOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined, QrcodeOutlined } from '@ant-design/icons/lib/icons';
import { Button, Col, Dropdown, Input, Menu, message, Modal, Row, Table, TableColumnsType, TableProps } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import { Sertifikasi } from './home.type';
import { useEffect, useState } from 'react';
import HttpService from '../../services/HttpService';
import { mapListSertifikasi } from './home.util';
import ExportQr from './ExportQr';
import QRCode from 'qrcode';
import Navbar from '../header/Navbar';

const Home = () => {
    const httpService = new HttpService();
    const [listSertifikasi, setListSertifikasi] = useState([]);
    const [filteredSertifikasi, setFilteredSertifikasi] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [deleteRecordId, setDeleteRecordId] = useState('');
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isReadOnly, setIsReadonly] = useState(false);

    const getListSertifikasi = async () => {
        try {
            const response = await httpService.getListSertifikasi();
            const mappedSertifikasi = mapListSertifikasi(response.data);
            if (response) {
                setListSertifikasi(mappedSertifikasi);
                setFilteredSertifikasi(mappedSertifikasi);
            }

        } catch (error) {
            // Handle error
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchQuery(query);
        search(query, ['noBerkas', 'noLot', 'produsen', 'varietas', 'kelasBenih', 'tanggalSelesaiUji', 'tanggalAkhirMasaEdar']); // Pass the query as an object with the key "query"
    };

    const generateAndDownloadQRCode = async (code: string) => {
        const qrCodeDataURL = await QRCode.toDataURL(`https://q.benihraja.com/${code}`);
        const link = document.createElement('a');
        link.href = qrCodeDataURL;
        link.download = `${code}.png`;
        link.click();
    };

    const search = async (query: string, fields: string[]) => {
        const filterResult = listSertifikasi.filter(item => {
            return fields.some(field => {
                const fieldValue = item[field];

                const stringValue = fieldValue as string;

                if (stringValue && typeof stringValue === 'string') {
                    return stringValue.toLowerCase().includes(query.toLowerCase());
                }
                return false;
            });
        });

        setFilteredSertifikasi(filterResult);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            setIsLoggedIn(true);
            getListSertifikasi();
        }
        const readonlyValue = localStorage.getItem('isReadOnly');
        if (readonlyValue) {
            try {
                const parsedValue = JSON.parse(readonlyValue);
                setIsReadonly(parsedValue === true);
            } catch (e) {
                console.error('Failed to parse isReadonly from localStorage', e);
            }
        }
    }, []);

    const columns: TableColumnsType<Sertifikasi> = [
        {
            title: 'No Berkas',
            dataIndex: 'noBerkas',
            showSorterTooltip: { target: 'full-header' },
            onFilter: (value: any, record: Sertifikasi) => record.noBerkas.indexOf(value) === 0,
            sorter: (a, b) => a.noBerkas.localeCompare(b.noBerkas),
            sortDirections: ['descend'],
        },
        {
            title: 'No LOT',
            dataIndex: 'noLot',
            defaultSortOrder: 'descend',
            onFilter: (value: any, record) => record.noLot.indexOf(value) === 0,
            sorter: (a, b) => a.noLot.localeCompare(b.noLot),
        },
        {
            title: 'Produsen',
            dataIndex: 'produsen',
            sorter: (a, b) => a.produsen.localeCompare(b.produsen),
        },
        {
            title: 'Varietas',
            dataIndex: 'varietas',
            sorter: (a, b) => a.varietas.localeCompare(b.varietas),

        },
        {
            title: 'Kelas Benih',
            dataIndex: 'kelasBenih',
            onFilter: (value: any, record) => record.kelasBenih.indexOf(value) === 0,
            sorter: (a, b) => a.kelasBenih.localeCompare(b.kelasBenih),
        },
        {
            title: 'Tanggal Selesai Uji',
            dataIndex: 'tanggalSelesaiUji',
            width: '15%',
            onFilter: (value: any, record) => record.tanggalSelesaiUji.indexOf(value) === 0,
            sorter: (a, b) => a.tanggalSelesaiUji.localeCompare(b.tanggalSelesaiUji),
        },
        {
            title: 'Tanggal Akhir Masa Edar',
            dataIndex: 'tanggalAkhirMasaEdar',
            width: '15%',
            onFilter: (value: any, record) => record.tanggalAkhirMasaEdar.indexOf(value) === 0,
            sorter: (a, b) => a.tanggalAkhirMasaEdar.localeCompare(b.tanggalAkhirMasaEdar),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Dropdown overlay={(
                    <Menu>
                        {!isReadOnly && (
                            <Menu.Item key="edit">
                                <Link to={`/edit/${record.code}`}>
                                    <Button type="link" icon={<EditOutlined />}>Edit</Button>
                                </Link>
                            </Menu.Item>
                        )}
                        {!isReadOnly && (
                            <Menu.Item key="delete">
                                <Button type="link" icon={<DeleteOutlined />} onClick={() => handleDelete(record.id)}>Delete</Button>
                            </Menu.Item>
                        )}
                        <Menu.Item key="detail">
                            <Button onClick={() => handleOpenView(record.code)} type="link" icon={<EyeOutlined />}>View</Button>
                        </Menu.Item>
                        <Menu.Item key="qr">
                            <Button onClick={() => generateAndDownloadQRCode(record.code)} icon={<QrcodeOutlined />}>Download Qr Code</Button>
                        </Menu.Item>
                    </Menu>
                )} trigger={['click']}>
                    <Button type="link">
                        Actions <DownOutlined />
                    </Button>
                </Dropdown>
            ),
        },
    ];

    const onChange: TableProps<Sertifikasi>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const handleDelete = (id: string) => {
        setDeleteRecordId(id);
        setDeleteDialogVisible(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await httpService.deleteSertifikasi(deleteRecordId);
            setDeleteDialogVisible(false);
            message.success('Sertifikasi deleted successfully');
            getListSertifikasi();
        } catch (error) {
            message.error('Failed to delete sertifikasi');
        }
    };

    const handleOpenView = (code: string) => {
        console.log(code);
        const url = `https://q.benihraja.com/${code}`;
        window.open(url, '_blank');
    };

    const handleCancelDelete = () => {
        setDeleteRecordId('');
        setDeleteDialogVisible(false);
    };

    if (!isLoggedIn) {
        return (<div></div>);
    } else {
        return (
            <div>
                <Navbar />
                <Content className="site-layout" style={{ marginTop: 64 }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: '650px' }}>
                        <Row justify="end" style={{ marginBottom: '20px' }}>
                            <Col>
                                {!isReadOnly && (
                                    <Link to='/form'><Button type="primary" icon={<PlusCircleOutlined />} style={{ background: '#059749', borderColor: '#059749' }}>Create</Button></Link>
                                )}

                            </Col>
                            <Col style={{ marginLeft: 10 }}>
                                <ExportQr listSertifikasi={listSertifikasi} />
                            </Col>
                        </Row>

                        <Row justify="end" style={{ marginBottom: '20px' }}>
                            <Col md={8} sm={12} xs={15}>
                                <Input placeholder='Search' value={searchQuery} onChange={handleInputChange} />
                            </Col>
                        </Row>

                        <div style={{ overflowX: 'auto' }}>
                            <Table
                                columns={columns}
                                dataSource={filteredSertifikasi}
                                onChange={onChange}
                                showSorterTooltip={{ target: 'sorter-icon' }}
                            />
                        </div>

                        <Modal
                            title="Confirm Delete"
                            visible={deleteDialogVisible}
                            onOk={handleConfirmDelete}
                            onCancel={handleCancelDelete}
                        >
                            <p>Are you sure you want to delete this sertifikasi?</p>
                        </Modal>
                    </div>
                </Content>
            </div>

        );
    }


};

export default Home;
