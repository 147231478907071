import { HomeFilled, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Header } from 'antd/es/layout/layout';
import '../../styles/CreateForm.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Navbar = () => {
    const navigate = useNavigate();
    const [isReadonly, setIsReadonly] = useState(false);

    useEffect(() => {
        const readonlyValue = localStorage.getItem('isReadOnly');
        if (readonlyValue) {
            try {
                const parsedValue = JSON.parse(readonlyValue);
                setIsReadonly(parsedValue === true);
            } catch (e) {
                console.error('Failed to parse isReadonly from localStorage', e);
            }
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <Header style={{ zIndex: 1, width: '100%', backgroundColor: 'white', display: 'flex', justifyContent: 'space-between' }}>
            <div className="logo">
                <img style={{ width: '50%' }} src="https://benihraja.com/wp-content/uploads/2021/11/Screenshot-from-2021-11-05-19-01-51-1.png" alt="Logo" />
            </div>
            <Menu theme="light" mode="horizontal" style={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                <Menu.Item key="home" icon={<HomeFilled />} onClick={() => navigate('/')}>
                    Home
                </Menu.Item>
                {!isReadonly && (
                    <Menu.Item key="users" icon={<UserOutlined />} onClick={() => navigate('/users')}>
                        Users
                    </Menu.Item>
                )}
                <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
                    Logout
                </Menu.Item>
            </Menu>
        </Header>
    );
};

export default Navbar;
