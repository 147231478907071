import { QrcodeOutlined } from '@ant-design/icons/lib/icons';
import { Button } from 'antd';
import { useEffect } from 'react';
import QRCode from 'qrcode';
import JSZip from 'jszip';

const ExportQr = ({ listSertifikasi }: { listSertifikasi: any[] }) => {
    useEffect(() => {
    }, []);

    const generateAndDownloadQRCodes = async () => {
        const zip = new JSZip();

        for (const item of listSertifikasi) {
            const qrCodeDataURL = await QRCode.toDataURL(`https://q.benihraja.com/${item.code}`);
            zip.file(`${item.code}.png`, qrCodeDataURL.split(';base64,')[1], { base64: true });
        }

        zip.generateAsync({ type: 'blob' }).then((content: Blob | MediaSource) => {
            const url = URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'qrcodes.zip');
            document.body.appendChild(link);
            link.click();
        });
    };

    return (
        <Button type='primary' icon={<QrcodeOutlined />} onClick={generateAndDownloadQRCodes}>
            Export QR Code
        </Button>
    );
};

export default ExportQr;
