import { Sertifikasi } from "../sertifikasi.type";
import dayjs from 'dayjs';

const DATE_FORMAT = 'YYYY-MM-DD';

const mapToSertifikasi = (data: any): Sertifikasi => {
  return {
    id: data.id || null,
    alamat: data.alamat || null,
    noBerkas: data.noBerkas || null,
    noLot: data.noLot || null,
    noInduk: data.noInduk || null,
    jenisTanaman: data.jenisTanaman || null,
    kelasBenih: data.kelasBenih || null,
    produsen: data.produsen || null,
    varietas: data.varietas || null,
    beratKemasan: data.beratKemasan || null,
    jumlahStokBenih: data.jumlahStokBenih || null,
    tanggalPanen: data.tanggalPanen ? dayjs(data.tanggalPanen, DATE_FORMAT) : null,
    tanggalSelesaiUji: data.tanggalSelesaiUji ? dayjs(data.tanggalSelesaiUji, DATE_FORMAT) : null,
    tanggalAkhirMasaEdar: data.tanggalAkhirMasaEdar ?  dayjs(data.tanggalAkhirMasaEdar, DATE_FORMAT) : null,
    noSeriAwal: data.noSeriAwal || null,
    noSeriAkhir: data.noSeriAkhir || null,
    kadarAir: data.kadarAir || null,
    dayaBerkecambah: data.dayaBerkecambah || null,
    benihMurni: data.benihMurni || null,
    cvlLapang: data.cvlLapang || null,
    kotoranBenih: data.kotoranBenih || null,
    gulma: data.gulma || null,
    benihWarnaLain: data.benihWarnaLain || null,
    code: data.code || null,
    ta: data.ta ? dayjs(data.ta, "YYYY") : null
  };
};

const util = {
  mapToSertifikasi,
};

export default util;
