import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table, TableColumnsType, message } from 'antd';
import '../../styles/CreateForm.css';
import { useEffect, useState } from 'react';
import { User } from './user.type';
import HttpService from '../../services/HttpService';
import { Link } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
import Navbar from '../header/Navbar';

const Users = () => {
    const httpService = new HttpService();
    const [users, setUsers] = useState<User[]>([]);
    const [deleteRecordId, setDeleteRecordId] = useState('');
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

    const handleDelete = (id: string) => {
        setDeleteRecordId(id);
        setDeleteDialogVisible(true);
    };

    const columns: TableColumnsType<User> = [
        {
            title: 'Email',
            dataIndex: 'email',
            showSorterTooltip: { target: 'full-header' },
            onFilter: (value: any, record: User) => record.email.indexOf(value) === 0,
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ['descend'],
        },
        {
            title: 'Name',
            dataIndex: 'name',
            showSorterTooltip: { target: 'full-header' },
            onFilter: (value: any, record: User) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['descend'],
        },
        {
            title: 'Permission',
            dataIndex: 'isReadOnly',
            showSorterTooltip: { target: 'full-header' },
            sortDirections: ['descend'],
            render(value, record, index) {
                return value ? 'Read Only' : 'Full Access';
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => handleDelete(record.id)}>Delete</Button>
            ),
        },
    ];

    const fetchUsers = async () => {
        try {
            const result = await httpService.getListUser();
            setUsers(result);
        } catch (error) {
            message.error('Failed fetch users');
            console.log(error);
        }
    }

    const handleConfirmDelete = async () => {
        try {
            await httpService.deleteUser(deleteRecordId);
            setDeleteDialogVisible(false);
            message.success('User deleted successfully');
            fetchUsers();
        } catch (error) {
            message.error('Failed to delete user');
        }
    };

    const handleCancelDelete = () => {
        setDeleteRecordId('');
        setDeleteDialogVisible(false);
    };

    useEffect(() => {
        fetchUsers()
    }, []);

    return (
        <div>
            <Navbar />
            <Content className="site-layout" style={{ marginTop: 64 }}>
                <div className="site-layout-background" style={{ padding: 24, minHeight: '650px' }}>
                    <Row justify="end" style={{ marginBottom: '20px' }}>
                        <Col>
                            <Link to='/users/form'><Button type="primary" icon={<PlusCircleOutlined />} style={{ background: '#059749', borderColor: '#059749' }}>Create</Button></Link>
                        </Col>
                    </Row>

                    <div style={{ overflowX: 'auto' }}>
                        <Table
                            columns={columns}
                            dataSource={users}
                            showSorterTooltip={{ target: 'sorter-icon' }}
                        />
                    </div>
                </div>
            </Content>
            <Modal
                title="Confirm Delete"
                visible={deleteDialogVisible}
                onOk={handleConfirmDelete}
                onCancel={handleCancelDelete}
            >
                <p>Are you sure you want to delete this user?</p>
            </Modal>
        </div>
    );
};

export default Users;

