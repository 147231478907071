import { User } from "./user.type";

export const mapToUserModel = (user: any): User => {
  return {
    id: user.id,
    email: user.email,
    name: user.name,
    isReadOnly: user.isReadOnly,
  };
};
