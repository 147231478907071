import { Button, Card, Col, Form, Input, Modal, Row, Switch, message } from 'antd';
import '../../styles/CreateForm.css';
import { useState } from 'react';
import Navbar from '../header/Navbar';
import { Content } from 'antd/es/layout/layout';
import { MailOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import HttpService from '../../services/HttpService';

const UserForm = ({ showForm, toggleForm }: any) => {
    const httpService = new HttpService();
    const navigate = useNavigate();

    const onFinish = async (values: any) => {
        try {
            await httpService.createUser(values);
            message.success('User created successfully');
            navigate('/users');
        } catch (error) {
            message.error('Failed to create user');
        }
    }

    return (
        <Content style={{ minHeight: '850px' }}>
            <Navbar />

            <Row justify={'center'}>
                <Col md={8} sm={24}>
                    <Card title="Register new user" style={{ marginTop: 50 }}>
                        <Form
                            name="registration_form"
                            initialValues={{ remember: true }}
                            layout="vertical"
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please input your name!' }]}
                            >
                                <Input prefix={<UserOutlined />} />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input prefix={<MailOutlined />} />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password prefix={<LockOutlined />} />
                            </Form.Item>
                            <Form.Item
                                label="Read only"
                                name="isReadOnly"

                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Register
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>


        </Content>

    );
};

export default UserForm;

